import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

const Container = styled.div`
	width: 100%;
	background-color: ${(props) => props.bgColor};

	content-visibility: ${(props) => props.cv};
	contain-intrinsic-size: 600px;

	margin-right: auto;
	margin-left: auto;

	@media (min-width: 1201px) {
		padding: ${(props) =>
			props.largeDesktopPadding ? props.largeDesktopPadding : `0 135px`};
	}

	@media (max-width: 1200px) {
		padding: ${(props) =>
			props.desktopPadding ? props.desktopPadding : `0 60px`};
	}

	@media (max-width: 1024px) {
		padding: ${(props) =>
			props.tabletPadding ? props.tabletPadding : `0 35px`};
	}

	@media (max-width: 768px) {
		padding: ${(props) =>
			props.mobilePadding ? props.mobilePadding : `0 15px`};

		contain-intrinsic-size: 300px;
	}
`;

export const FlexContainer = ({
	children,
	largeDesktopPadding,
	desktopPadding,
	tabletPadding,
	mobilePadding,
	id,
	bgColor,
	cv,
}) => (
	<Container
		id={id}
		largeDesktopPadding={largeDesktopPadding}
		desktopPadding={desktopPadding}
		tabletPadding={tabletPadding}
		mobilePadding={mobilePadding}
		bgColor={bgColor}
		cv={cv ? `auto` : `visible`}
	>
		{/* <LazyLoad height={600}> */}
		{children}
		{/* </LazyLoad> */}
	</Container>
);
